import React, { useEffect } from 'react';
import { isBrowser } from '../utils';

export default () => {
  useEffect(() => {
    isBrowser() && window.location.replace('/pricing');
  }, []);

  return null;
};
